<template>
  <div>
    <v-select
      v-if="!type"
      v-model="items.selected"
      :items="items.answers"
      item-text="label"
      item-value="value"
      :label="items.label"
      :disabled="items.disabled"
      :placeholder="items.placeholder"
      atta
      outlined
      dense
      :rules="items.rules"
      :value="items.selected"
      @change="change"
      :style="cssVars"
      no-data-text="No hay información"
      :menu-props="{ offsetY: true }"
      :loading="loading"
      ><template #item="{ item }">
        <span class="labelStyle"> {{ item.label }}</span>
      </template></v-select
    >
    <v-autocomplete
      v-else
      v-model="items.selected"
      :items="items.answers"
      item-text="label"
      item-value="value"
      :label="items.label"
      :disabled="items.disabled"
      :placeholder="items.placeholder"
      attach
      outlined
      dense
      :menu-props="{ offsetY: true }"
      :rules="items.rules"
      :value="items.selected"
      @change="change"
      :style="cssVars"
      no-data-text="No hay información"
      :loading="loading"
      ><template #item="{ item }">
        <span class="labelStyle"> {{ item.label }}</span>
      </template></v-autocomplete
    >
  </div>
</template>
<script>
export default {
  name: "drop",
  props: ["fields", "type", "color", "loading", "order"],
  data() {
    return {
      field: this.items,
    };
  },
  methods: {
    change() {
      this.$emit("change", true);
    },
  },
  computed: {
    items() {
      return this.fields;
    },
    cssVars() {
      return {
        "--bg-color": this.items.selected === "" ? "#BEBEBE" : "#A9A9A9",
        "--bg-color-text": this.inputVal === "" ? "#A0A0A0" : "#A0A0A0",
      };
    },
    mergeWithNewData() {
      return [...new Set(this.items.answers)].sort();
    },
  },
  created() {
    this.$emit("fields", this.field);
    if (this.order === true)
      this.items.answers.sort((a, b) => a.label.localeCompare(b.label));
  },
  mounted() {
    this.$emit("fields", this.field);
  },
  updated() {
    this.field = this.fields;
  },
};
</script>

<style scoped>
.labelStyle {
  font-family: Inter !important;
  font-weight: 400;
  font-size: 12px;
  color: #050c42 !important;
}
::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #050c42 !important;
}

::v-deep .v-text-field--outlined.v-input--dense .v-label {
  top: 10px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #050c42;
}

.v-list .v-list-item--link:before {
  background-color: #fff !important;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
::v-deep.v-input:hover .v-label {
  color: var(--bg-color-text) !important;
}
::v-deep.v-input .v-label {
  color: var(--bg-color-text) !important;
}

::v-deep .v-text-field:hover > .v-input__control > .v-input__slot fieldset {
  border: 1px solid var(--bg-color);
}
::v-deep .v-text-field > .v-input__control > .v-input__slot fieldset {
  border: 1px solid var(--bg-color);
}
.v-input:hover >>> label {
  color: var(--bg-color-text) !important;
}
.v-input >>> label {
  color: var(--bg-color-text) !important;
}

::v-deep.theme--light.v-select .v-select__selections {
  color: #050c42;
}
</style>
