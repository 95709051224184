<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      :header-props="{ 'sort-icon': 'bi bi-caret-up-fill' }"
      no-data-text="No se han encontrado solicitudes"
      no-results-text="No se han encontrado solicitudes"
      loading-text="Cargando solicitudes..."
      class="elevation-2"
    >
      <template v-slot:top>
        <slot name="table-top" />
      </template>

      <template v-slot:[`item.down`]="{ item }">
        <a @click.prevent="ActionButton(item)" class="black--text">
          <i :href="item" download color="black" class="icon bi bi-download">{{
            item.down
          }}</i>
        </a>
      </template>

      <template v-slot:[`item.eyes`]="{ item }">
        <v-tooltip top color="info" dark>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="ActionButtonOpenUrlDocument(item)"
              color="info"
              text
              small
              fab
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-download</v-icon></v-btn
            >
          </template>
          <span>Descargar documento de la solicitud n°{{ item.id }}</span>
        </v-tooltip>

        <v-tooltip top color="primary" dark>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              fab
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="ActionButton(item)"
              :disabled="item.estado != 'SOL'"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar solicitud n°{{ item.id }}</span>
        </v-tooltip>

        <v-tooltip top color="warning" dark>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              fab
              v-bind="attrs"
              v-on="on"
              small
              color="warning"
              @click="ActionButtonPlus(item)"
              :disabled="
                item.estado != 'SOL' ||
                !item ||
                !('id_producto' in item) ||
                (item.id_producto ? true : false) ||
                item.id_producto !== ''
              "
            >
              <v-icon>mdi-archive-cog</v-icon>
            </v-btn>
          </template>
          <span>Gestionar producto de la solicitud n°{{ item.id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.estado_descripcion`]="{ item }">
        <v-chip :color="status[item.estado].color" outlined label>
          <!-- @click="item.estado = !item.estado" -->
          <v-icon left>
            {{
              status[item.estado]
                ? status[item.estado].icon
                : status.default.icon
            }}
          </v-icon>
          {{ item.estado_descripcion }}
        </v-chip>
      </template>

      <template v-slot:[`item.asunto`]="{ item }">
        <h4 class="text-left">
          {{ item.asunto.substring(0, 30)
          }}<span v-if="item.asunto.length > 30">...</span>
        </h4>
      </template>
    </v-data-table>
    <v-layout v-if="footer && items.length > 0" class="align-center mt-3 ml-1">
      <h4 class="counterStyle">
        {{ 1 + itemsPerPage * (page - 1) }} al {{ itemsPerPage * page }}
      </h4>
      <v-flex xs1 class="ml-3">
        <v-select
          dense
          hide-details
          single-line
          :value="itemsPerPage"
          @change="changenumber"
          :items="perPageChoices"
        >
        </v-select>
      </v-flex>

      <v-flex class="text-end">
        <v-btn
          small
          elevation="0"
          :disabled="!paginaranterior"
          @click="prevPage"
          class="mr-1"
          ><v-icon x-small class="mr-2">bi bi-chevron-double-left</v-icon>
          Anterior</v-btn
        >
        <v-btn
          class="ml-1"
          small
          elevation="0"
          @click="nextPage"
          :disabled="!paginar"
          >Siguiente
          <v-icon x-small class="ml-2"
            >bi bi-chevron-double-right</v-icon
          ></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { productStatus } from "@/store/resources/status.js";
export default {
  name: "simpletable",
  // props: ["items", "headers", "footer", "search", "actionIcon"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: [Array],
      require: true,
    },
    footer: {
      type: Boolean,
      require: false,
    },
    paginar: {
      type: Boolean,
      require: false,
    },
    paginaranterior: {
      type: Boolean,
      require: false,
    },
    actionIcon: {
      type: [String, Number, Boolean],
      require: true,
    },
    ActionButton: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    ActionButtonPlus: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
    ActionButtonOpenUrlDocument: {
      type: Function,
      default(item) {
        return item;
      },
      require: false,
    },
  },
  data: () => ({
    page: 1,
    itemSelected: null,
    itemsPerPage: 10,
    nextBtnDisabled: false,
    prevBtnDisabled: true,
    perPageChoices: [
      { text: "10 ", value: 10 },
      { text: "15 ", value: 15 },
      { text: "20 ", value: 20 },
    ],
  }),
  methods: {
    nextPage() {
      if (this.page < this.pageCount) this.page++;
      if (this.page === this.pageCount) {
        this.nextBtnDisabled = true;
        this.prevBtnDisabled = false;
      } else this.prevBtnDisabled = false;
      this.$emit("nextpage");
    },

    prevPage() {
      if (this.page > 1) this.page--;
      if (this.page === 1) {
        this.prevBtnDisabled = true;
        this.nextBtnDisabled = false;
      } else this.nextBtnDisabled = false;
      this.$emit("previouspage");
    },

    changenumber(limite) {
      this.itemsPerPage = parseInt(limite, 10);
      this.$emit("limite", limite);
    },
  },

  computed: {
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage);
    },
    status() {
      return productStatus;
    },
  },
};
</script>
