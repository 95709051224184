<template>
  <div>
    <TableSolicitudes
      :items="getSolicitudes"
      :headers="headers_two"
      :loading="tableLoading"
      :footer="getSolicitudes.length > 0 ? true : false"
      :paginar="paginar"
      :paginaranterior="retrocederPagina"
      :ActionButton="EyesClicked"
      :ActionButtonPlus="PlusClicked"
      :ActionButtonOpenUrlDocument="OpenUrlDocument"
      @nextpage="nextpage"
      @previouspage="previouspage"
      @limite="limite"
    >
      <template v-slot:table-top>
        <v-toolbar flat class="align-end">
          <v-select
            :items="Estado.answers"
            v-model="Estado.selected"
            dense
            label="Estado"
            placeholder="Filtrar por el estado"
            :loading="tableLoading"
            hide-details
            @change="Buscar()"
            style="max-width: 250px"
          ></v-select>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            color="success"
            text
            href="/docs/formato-solicitud-productos.docm"
            target="_blank"
            >Plantilla <v-icon right>mdi-download</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            fab
            small
            outlined
            class="mr-3"
            :loading="tableLoading"
            @click="cargarData()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn color="primary" dark @click="state_modal = true">
            Nueva solicitud <v-icon right>mdi-receipt-text-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </TableSolicitudes>

    <Modalsolicitud
      v-if="state_modal"
      v-model="state_modal"
      :title="selected ? 'Modificar una solicitud' : 'Crear una solicitud'"
      @close="closemodal"
      :datamodal="selected"
      @ActionButtonOpenUrlDocument="OpenUrlDocument"
    />

    <ModalsolicitudPregunta
      :title="`Gestionar producto de la solicitud nro ${
        selected ? selected.id : ''
      }`"
      v-model="state_modal_pregunta"
      @close="state_modal_pregunta = false"
      @action="handlerSelectRequestAction"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TableSolicitudes from "@/components/Tables/TableSolicitudes";
import Modalsolicitud from "@/components/Modal/Modal-solicitud";
import ModalsolicitudPregunta from "@/components/Modal/Modal-Solicitud-Pregunta";

export default {
  name: "SolicitudesPage",
  components: {
    Modalsolicitud,
    TableSolicitudes,
    ModalsolicitudPregunta,
  },
  data() {
    return {
      limitefiltro: 10,
      paginaactual: 1,
      paginar: false,
      isfiltering: false,
      FilteredData: [],
      // solicitudes: [],
      state_modal: false,
      state_value: null,
      state_modal_pregunta: false,
      selected: null, // Solicitud seleccionada
      // bandera: false,
      loadingbotonbuscar: false,
      tableLoading: false,
      headers_two: [
        {
          text: "Nº",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Descripción",
          value: "asunto",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "fecha_solicitud",
          sortable: false,
        },
        {
          text: "Producto",
          value: "nombre_producto",
          sortable: false,
        },
        {
          text: "Usuario",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado_descripcion",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "eyes",
          align: "right",
          sortable: false,
        },
      ],
      Plazo: {
        label: "Plazo",
        answers: [
          "Todos",
          "1 a 45 Días (Verde)",
          "46 a 90 Días (Verde)",
          "1 a 15 Días (Normal)",
          "16 a 30 Días (Normal)",
          "31 a 60 Días (Normal)",
        ],
        selected: "",
      },
      Estado: {
        label: "Estado",
        answers: [
          "Todos",
          "Solicitados",
          "En configuración",
          "En certificación",
          "Activo",
          "Rechazado",
        ],
        selected: "",
      },
    };
  },
  // ------------------------------METHODS
  methods: {
    ...mapActions("Solicitudes", [
      "loadSolicitudes",
      "loadSolicitudesFilter",
      "paginacion",
      "openUrlDocument",
    ]),
    closemodal() {
      this.state_modal = false;
      this.selected = null;
    },

    EyesClicked(item) {
      this.state_modal = true;
      this.selected = item;
    },

    PlusClicked(item) {
      this.state_modal_pregunta = true;
      this.selected = item;
      this.$store.commit("setSolicitud", item);
    },

    async OpenUrlDocument(item) {
      let url = await this.openUrlDocument(item.url);
      window.open(url, "Documento", null);
    },

    // FILTER BUTTON
    Buscar() {
      const flag = this.Estado.selected;
      switch (flag) {
        case "Solicitados":
          this.filtrar("SOL");
          break;

        case "En configuración":
          this.filtrar("CON");
          break;

        case "En certificación":
          this.filtrar("CER");
          break;

        case "Activo":
          this.filtrar("ACT");
          break;

        case "Rechazado":
          this.filtrar("REC");
          break;

        case "Todos":
          this.filtrar("TODOS");
          break;

        default:
          this.isfiltering = false;
          break;
      }
    },

    async filtrar(flag) {
      this.tableLoading = true;
      let limit = this.limitefiltro;
      let query = {
        limit,
        estado: flag,
      };

      this.loadingbotonbuscar = true;
      await this.loadSolicitudesFilter(query);
      // let filters = this.getSolicitudes;
      this.paginar = this.getPaginacion;

      this.loadingbotonbuscar = false;
      this.paginaactual = 1;
      this.isfiltering = true;
      // this.solicitudes = filters;
      this.tableLoading = false;
    },

    async cargarData() {
      this.tableLoading = true;
      await this.loadSolicitudes();
      this.paginar = this.getPaginacion;
      // this.solicitudes = this.getSolicitudes;
      // this.bandera = true;
      this.paginar = this.getPaginacion;
      this.tableLoading = false;
    },

    async previouspage() {
      this.tableLoading = true;
      let estado = this.getValorSelectEstado();
      let limit = this.limitefiltro;
      this.paginaactual = Number(this.paginaactual) - 1;
      let desde;
      if (this.paginaactual == 1) {
        desde = 0;
      } else {
        desde = Number(this.getSolicitudes.length) * Number(this.paginaactual);
      }

      let queryparams = {
        limit,
        desde,
        estado,
      };
      await this.paginacion(queryparams);
      this.paginar = this.getPaginacion;
      // this.solicitudes = this.getSolicitudes;
      this.tableLoading = false;
    },
    async nextpage() {
      this.tableLoading = true;
      let estado = this.getValorSelectEstado();
      let limit = this.limitefiltro;
      let desde =
        Number(this.getSolicitudes.length) * Number(this.paginaactual);

      this.paginaactual = Number(this.paginaactual) + 1;
      let queryparams = {
        limit,
        desde,
        estado,
      };
      await this.paginacion(queryparams);
      this.paginar = this.getPaginacion;
      // this.solicitudes = this.getSolicitudes;
      this.tableLoading = false;
    },

    async limite(limite) {
      this.paginaactual = 1;
      this.limitefiltro = limite;

      let limit = limite;
      let estado = this.getValorSelectEstado();
      let desde = 0;

      let queryparams = {
        limit,
        desde,
        estado,
      };
      await this.paginacion(queryparams);
      this.paginar = this.getPaginacion;
      // this.solicitudes = this.getSolicitudes;
    },

    getValorSelectEstado() {
      let estado;
      let flag = this.Estado.selected;
      switch (flag) {
        case "En Revisión":
          estado = "REV";
          break;

        case "Pendiente":
          estado = "PEN";
          break;

        case "Rechazado":
          estado = "REC";
          break;

        case "Aceptado":
          estado = "ACE";
          break;

        case "Todos":
          estado = "TODOS";
          break;

        default:
          estado = "SIN_ESTADO";
          break;
      }
      return estado;
    },

    handlerSelectRequestAction(item) {
      const code = item.code;
      let routerName = "";
      switch (code) {
        case "create":
          routerName = "NewProduct";
          break;

        // case 'update':

        //   break;

        default:
          routerName = "Enconstruccion";
          break;
      }
      this.$router.push({
        name: routerName,
      });
    },
  },
  // ------------------------------COMPUTED
  computed: {
    ...mapGetters("Solicitudes", ["getSolicitudes", "getPaginacion"]),

    usermodal() {
      return this.state_modal;
    },
    usermodalpregunta() {
      return this.state_modal_pregunta;
    },
    retrocederPagina() {
      if (this.paginaactual > 1) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.cargarData();
    this.$store.commit("setPage", { page: this.$route.meta.page });
  },
};
</script>
