<template>
  <v-dialog :value="value" persistent max-width="600">
    <v-card>
      <v-card-title class="primary white--text">
        {{ title }}
      </v-card-title>

      <v-row no-gutters class="pa-3">
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="12"
          md="6"
          class="pa-1"
        >
          <v-card
            class="mx-auto"
            :color="item.color"
            dark
            @click="handlerSelectItem(item)"
          >
            <v-card-title>
              <v-icon large left>
                {{ item.icon }}
              </v-icon>
            </v-card-title>

            <v-card-text class="text-h5 font-weight-bold">
              {{ item.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
      description: "Titulo de la modal",
    },
  },
  data: () => ({
    items: [
      {
        id: 1,
        text: "Crear Producto",
        icon: "mdi-archive-plus",
        color: "info lighten-1",
        code: "create",
      },
      // {
      //   id: 2,
      //   text: "Editar Producto.",
      //   icon: "mdi-archive-edit",
      //   color: "info darken-1",
      //   code: 'update',
      // },
      // {
      //   text: "Copiar y modificar producto existente.",
      //   icon: "bi bi bi-intersect",
      // },
    ],
  }),
  methods: {
    closebtn() {
      this.$emit("close");
    },
    handlerSelectItem(item) {
      this.$emit("action", item);
    },
  },
};
</script>
