<template>
  <v-dialog persistent :value="value" max-width="600" scrollable>
    <v-card>
      <v-card-title class="primary white--text">
        {{ title }}
      </v-card-title>

      <v-layout wrap class="px-6 mt-6">
        <v-fade-transition>
          <v-flex
            lg10
            v-if="
              requeridasunto ||
              requeridfile ||
              requeridmotivorechazo ||
              extensioninvalida
            "
          >
            <v-alert dense outlined type="warning">
              <span v-if="requeridasunto">
                <strong>Asunto</strong> es obligatorio.
              </span>
              <span v-else-if="requeridfile">
                Debe adjuntar un Documento.
              </span>
              <span v-else-if="requeridmotivorechazo">
                Debe justificar el motivo de rechazo.
              </span>
              <span v-else-if="extensioninvalida">
                El archivo debe ser del tipo: {{ extensions.join(", ") }}
              </span>
            </v-alert>
          </v-flex>
        </v-fade-transition>

        <v-flex md7 class="px-1">
          <TextField :fields="fecha_solicitud" />
        </v-flex>

        <v-flex md5 class="px-1">
          <!--<SelectField :fields="estado" />-->
          <SelectLabel :fields="estado" color="#2381C1" v-on:change="change" />
        </v-flex>

        <v-flex md12 v-if="mostrarinputrechazo">
          <TextField :fields="motivo_rechazo" :required="mostrarinputrechazo" />
        </v-flex>

        <v-flex md12 class="px-1">
          <TextField :fields="usuario" />
        </v-flex>

        <v-flex md12 class="px-1">
          <v-textarea
            outlined
            name="input-4-2"
            label="Asunto"
            rows="3"
            :fields="motivo"
            :value="asunto"
            v-model="asunto"
          ></v-textarea>
        </v-flex>

        <v-flex lg10 v-if="flagfile">
          <v-file-input
            counter
            truncate-length="18"
            class="mr-2 ml-2"
            outlined
            dense
            label="Adjuntar archivo"
            v-model="file"
            accept=".doc,.docx,application/msword,application/pdf"
          ></v-file-input>
        </v-flex>

        <v-flex lg10 class="descargar-documento" v-if="doesitdata">
          <a @click="openUrlDocument" class="black--text mr-2 ml-2">
            <i class="bi bi-download">
              Descargar documento actual:
              <b>
                {{
                  datamodal.documento_descripcion.length > 55
                    ? datamodal.documento_descripcion.substring(0, 55) + "..."
                    : datamodal.documento_descripcion
                }}
              </b></i
            >
          </a>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')" :disabled="loading">
          Cerrar
        </v-btn>
        <v-btn color="primary" @click="actionBtn" :loading="loading">
          {{ namebutton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import SelectLabel from "@/components/Inputs/SelectLabel";

export default {
  props: {
    datamodal: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectLabel,
  },
  data() {
    return {
      doesitdata: false,
      flagfile: true,
      asunto: "",
      namebutton: "Guardar",
      motivoSave: "",
      file: [],
      extensions: ["pdf", "docx", "doc"],
      requeridasunto: false,
      requeridfile: false,
      requeridmotivorechazo: false,
      extensioninvalida: false,
      loading: false,
      nombreusuario: "",
      mostrarinputrechazo: false,
      fecha_solicitud: {
        label: "Fecha solicitud",
        text: "",
        disabled: false,
      },
      estado: {
        label: "Estado",
        answers: [
          { label: "Solicitado", value: "Solicitado" },
          { label: "Rechazado", value: "Rechazado" },
        ],
        selected: "",
      },

      motivo_rechazo: {
        label: "Motivo de rechazo",
        text: "",
        disabled: false,
      },

      usuario: {
        label: "Usuario solicitud",
        text: "",
        disabled: false,
      },

      motivo: {
        label: "Asunto",
        text: "",
        disabled: false,
      },

      documento: {
        label: "documentos",
        text: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.UserData;
    },
  },
  methods: {
    ...mapActions("Solicitudes", ["crearSolicitud", "actualizarSolicitud"]),
    closebtn() {
      this.$emit("close");
    },
    openUrlDocument() {
      this.$emit("ActionButtonOpenUrlDocument", this.datamodal);
    },
    isdisable() {
      this.asunto = this.datamodal.asunto;
      let estado = this.datamodal.estado;
      if (
        estado == "REC" ||
        estado == "ACT" ||
        estado == "CER" ||
        estado == "CON"
      ) {
        if (estado == "REC") {
          this.mostrarinputrechazo = true;
          this.motivo_rechazo = {
            ...this.motivo_rechazo,
            disabled: true,
          };
        }
        this.flagfile = false;
        this.namebutton = "Cerrar";

        this.fecha_solicitud = {
          ...this.fecha_solicitud,
          disabled: true,
        };

        this.estado = {
          ...this.estado,
          disabled: true,
        };

        this.usuario = {
          ...this.usuario,
          disabled: true,
        };

        this.motivo = {
          ...this.motivo,
          disabled: true,
        };
      } else {
        this.flagfile = true;
        this.namebutton = "Actualizar";
        this.mostrarinputrechazo = false;

        this.fecha_solicitud = {
          ...this.fecha_solicitud,
          disabled: true,
        };

        this.estado = {
          ...this.estado,
          disabled: false,
        };

        this.motivo_rechazo = {
          ...this.motivo_rechazo,
          disabled: false,
        };

        this.usuario = {
          ...this.usuario,
          disabled: true,
        };

        this.motivo = {
          ...this.motivo,
          disabled: false,
        };
      }
    },
    putdata() {
      let data = this.datamodal;
      this.asunto = data.asunto;

      if (this.datamodal.estado == "REC") {
        this.motivo_rechazo = {
          ...this.motivo_rechazo,
          text: data.motivo_rechazo,
        };
      }

      this.fecha_solicitud = {
        ...this.fecha_solicitud,
        text: data.fecha_solicitud,
      };

      let estadoNombre = this.getStateSelect("nombre");
      let nuevoEstado = { label: estadoNombre, value: estadoNombre };
      this.estado = {
        ...this.estado,
        answers: [
          { label: "Solicitado", value: "Solicitado" },
          { label: "Rechazado", value: "Rechazado" },
          nuevoEstado,
        ],
        selected: nuevoEstado,
      };

      this.usuario = {
        ...this.usuario,
        text: this.user.nombre,
      };

      this.motivo = {
        ...this.motivo,
        text: data.asunto,
      };
    },
    actionBtn() {
      if (this.datamodal) {
        let state = this.datamodal.estado;
        if (
          state == "REC" ||
          state == "ACT" ||
          state == "CER" ||
          state == "CON"
        ) {
          this.closebtn();
          return false;
        }
      }
      this.guardar();
    },
    getFileExtension1(filename) {
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onload = () => {
            var fileData = reader.result.toString().split(",");
            let myFile = fileData[1];
            resolve(myFile);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        } catch (e) {
          reject(false);
        }
      });
    },
    getStateSelect(devolver = "codigo") {
      //si es nuevo me devuelve el codigo dependiendo del nombre del estado y si no me devuelve el nombre dependiendo del estado.
      let state;
      if (devolver == "codigo") {
        switch (this.estado.selected) {
          case "Solicitado":
            state = "SOL";
            break;
          case "En configuración":
            state = "CON";
            break;
          case "Certificación":
            state = "CER";
            break;
          case "Activo":
            state = "ACT";
            break;
          case "Rechazado":
            state = "REC";
            break;
          default:
            state = "SOL";
            break;
        }
        return state;
      }
      let estado = this.datamodal.estado;
      switch (estado) {
        case "SOL":
          state = "Solicitado";
          break;
        case "CON":
          state = "En configuración";
          break;
        case "CER":
          state = "Certificación";
          break;
        case "ACT":
          state = "Activo";
          break;
        case "REC":
          state = "Rechazado";
          break;
        default:
          state = "Solicitado";
          break;
      }
      return state;
    },
    async nuevoRegistro() {
      let asunto = this.asunto;
      let archivo = this.file;
      let { correo_electronico: correo, usuario } = this.user;

      if (asunto.length < 2) {
        this.requeridasunto = true;
        return;
      }
      this.requeridasunto = false;
      if (!archivo.name) {
        this.requeridfile = true;
        return;
      }
      this.requeridfile = false;

      let estado = this.getStateSelect();
      let date = this.currentDate();
      let user = this.usuario.text;
      let extension = this.getFileExtension1(archivo.name);
      if (this.validateExtension(extension)) {
        this.extensioninvalida = true;
        return;
      }
      let base64 = await this.toBase64(archivo);

      let data = {
        fecha_solicitud: date,
        estado,
        usuario,
        correo,
        nombre: user,
        asunto,
        extension,
        documento_descripcion: archivo.name,
        documento: base64,
      };

      if (base64) {
        this.loading = true;
        let respuesta = await this.crearSolicitud(data);
        this.loading = false;
        this.mensaje(respuesta);
      }
    },
    async actualizarRegistro() {
      let asunto = this.asunto;
      let archivo = this.file;

      if (asunto.length < 2) {
        this.requeridasunto = true;
        return;
      }
      this.requeridasunto = false;

      // eslint-disable-next-line no-unused-vars
      let cambiar_documento = false;
      let extension;
      let documento_descripcion;
      let base64 = null;
      if (archivo.name || archivo.name != undefined) {
        cambiar_documento = true;
        extension = this.getFileExtension1(archivo.name);
        documento_descripcion = archivo.name;
        base64 = await this.toBase64(archivo);
      } else {
        extension = this.datamodal.extension;
        documento_descripcion = this.datamodal.documento_descripcion;
      }

      if (this.validateExtension(extension)) {
        this.extensioninvalida = true;
        this.file = [];
        return;
      }

      let user = this.usuario.text;
      let estado = this.getStateSelect();
      let { correo_electronico: correo, usuario } = this.user;
      let motivo_rechazo = estado == "REC" ? this.motivo_rechazo.text : "";
      if (estado == "REC" && motivo_rechazo.length < 1) {
        this.requeridmotivorechazo = true;
        return;
      }

      let data = {
        id: this.datamodal.id,
        fecha_solicitud: this.datamodal.fecha_solicitud,
        estado,
        usuario,
        nombre: user,
        asunto,
        correo,
        motivo_rechazo,
        cambiar_documento,
        extension,
        documento_descripcion,
        url: this.datamodal.url,
      };
      if (base64) {
        data.documento = base64;
      }

      this.loading = true;
      let respuesta = await this.actualizarSolicitud(data);
      this.loading = false;
      this.mensaje(respuesta);
    },
    async guardar() {
      if (this.namebutton === "Guardar") {
        this.nuevoRegistro();
      } else if (this.namebutton === "Actualizar") {
        this.actualizarRegistro();
      }
    },
    currentDate() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return (date =
        month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`);
    },
    placeDefaultData(date, user) {
      this.fecha_solicitud = {
        ...this.fecha_solicitud,
        text: date,
        disabled: true,
      };
      this.estado = {
        ...this.estado,
        label: "Estado",
        answers: ["Solicitado", "Rechazado"],
        selected: "Solicitado",
        disabled: true,
      };
      this.usuario = {
        ...this.usuario,
        label: "Usuario solicitud",
        text: user,
        disabled: true,
      };
    },
    option() {
      if (!this.datamodal) {
        this.doesitdata = false;
        this.flagfile = true;
        this.namebutton = "Guardar";
        this.placeDefaultData(
          this.currentDate(),
          this.$store.getters.UserData.nombre
        );
      } else {
        this.doesitdata = true;
        this.putdata();
        this.isdisable();
      }
    },
    mensaje(flag) {
      if (flag) {
        this.closebtn();
        Swal.fire("Exitoso!", "Su solicitud fue registrada", "success");
        this.asunto = "";
        this.file = [];
      } else {
        Swal.fire(
          "Error!",
          "Hubo un error al tratar de guardar su solicitud",
          "error"
        );
      }
    },
    change() {
      this.requeridmotivorechazo = false;
      if (this.estado.selected == "Solicitado") {
        this.mostrarinputrechazo = false;
      } else {
        this.mostrarinputrechazo = true;
      }
    },
    validateExtension(val) {
      return !this.extensions.includes(val);
    },
  },

  created() {
    this.option();
  },
};
</script>
